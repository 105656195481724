<template>
  <div>
    <div v-if="isLoading" class="text-center pt-3">
      <b-spinner label="Loading..." />
    </div>

    <div v-else class="text-center">
      <div data-paperform-id="6pzjfv1e"></div>
    </div>
  </div>
</template>
<script>
import {
  BSpinner,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BSpinner,
    BButton,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    this.surveyLaunch();
    this.isLoading = false;
  },
  methods: {
    surveyLaunch() {
      const script = document.createElement("script");
      script.src = "https://paperform.co/__embed.min.js";
      document.body.appendChild(script);
    }
  },
};
</script>

<style>
</style>
